// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-category-js": () => import("../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-details-js": () => import("../src/templates/details.js" /* webpackChunkName: "component---src-templates-details-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-banks-js": () => import("../src/pages/all-banks.js" /* webpackChunkName: "component---src-pages-all-banks-js" */),
  "component---src-pages-all-categories-js": () => import("../src/pages/all-categories.js" /* webpackChunkName: "component---src-pages-all-categories-js" */),
  "component---src-pages-all-stores-js": () => import("../src/pages/all-stores.js" /* webpackChunkName: "component---src-pages-all-stores-js" */),
  "component---src-pages-bank-lists-pages-js": () => import("../src/pages/bank-lists-pages.js" /* webpackChunkName: "component---src-pages-bank-lists-pages-js" */),
  "component---src-pages-contact-us-js": () => import("../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-terms-js": () => import("../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

